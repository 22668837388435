import React, { useEffect, useRef, useState } from "react";
import AlertBtn from "./AlertBtn";
import LogoContainer from "../../LogoContainer";
import { useTheme } from "@/app/contexts/ThemeContext";
import styles from "@/styles/desktop/Header.module.scss";
import ExploreContainer from "./ExploreContainer";
import ProductContainer from "./ProductContainer";
import { HeaderProps } from "@/app/interface/components";
import CustomLink from "../../CustomLink";

const HeaderDesktop: React.FC<HeaderProps> = ({ setIsAlertModalOpen }) => {
	const { theme, toggleTheme } = useTheme();
	const menuContentRef = useRef<HTMLDivElement>(null);
	const [isSticky, setIsSticky] = useState(false);
	const [isExploreOpen, setIsExploreOpen] = useState<boolean>(false);
	const [isProductOpen, setIsProductOpen] = useState<boolean>(false);
	const [closingMenu, setClosingMenu] = useState<string | null>(null);

	const handleScroll = () => {
		const header = menuContentRef.current;
		const sticky = header!.offsetTop;

		if (window.scrollY > sticky) {
			setIsSticky(true);
		} else {
			setIsSticky(false);
		}
	};

	useEffect(() => {
		document.body.className = theme;
	}, [theme]);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (
				menuContentRef.current &&
				!menuContentRef.current.contains(event.target as Node)
			) {
				setIsExploreOpen(false);
				setIsProductOpen(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const srcMode =
		theme === "light"
			? "https://gabcomercio.azureedge.net/azul-agro/general/dark.svg"
			: "https://gabcomercio.azureedge.net/azul-agro/general/light.svg";
	const altMode =
		theme === "light" ? "Ativar tema escuro" : "Ativar tema claro";

	const srcLogo =
		theme === "light"
			? "https://gabcomercio.azureedge.net/azul-agro/general/logo.svg"
			: "https://gabcomercio.azureedge.net/azul-agro/general/logoDark.svg";
	const srcLineLogo =
		theme === "light"
			? "https://gabcomercio.azureedge.net/azul-agro/general/lineLogo.svg"
			: "https://gabcomercio.azureedge.net/azul-agro/general/lineLogoDark.svg";
	const srcLogoNH =
		theme === "light"
			? "https://gabcomercio.azureedge.net/azul-agro/general/logoNH.svg"
			: "https://gabcomercio.azureedge.net/azul-agro/general/logoNHDark.svg";

	const handleMenuClick = (menu: "explore" | "product") => {
		if (closingMenu) return;

		if (menu === "explore") {
			if (isProductOpen) {
				setClosingMenu("product");
				setIsProductOpen(false);
				setTimeout(() => {
					setIsExploreOpen(true);
					setClosingMenu(null);
				}, 300); // Ajuste o tempo de acordo com a animação de fechamento
			} else {
				setIsExploreOpen(!isExploreOpen);
			}
		}

		if (menu === "product") {
			if (isExploreOpen) {
				setClosingMenu("explore");
				setIsExploreOpen(false);
				setTimeout(() => {
					setIsProductOpen(true);
					setClosingMenu(null);
				}, 300); // Ajuste o tempo de acordo com a animação de fechamento
			} else {
				setIsProductOpen(!isProductOpen);
			}
		}
	};

	return (
		<header className={styles.header}>
			<AlertBtn
				setIsExploreOpen={setIsExploreOpen}
				setIsProductOpen={setIsProductOpen}
				setIsAlertModalOpen={setIsAlertModalOpen}
				styles={styles}
			/>
			<div
				className={`${styles.main}${isSticky ? " " + styles.headerFixed : ""}`}
				ref={menuContentRef}
			>
				<div className={styles.menu}>
					<LogoContainer
						srcLogo={srcLogo}
						srcLineLogo={srcLineLogo}
						srcLogoNH={srcLogoNH}
					/>
					<div className={styles.midContent}>
						<button
							type="button"
							onClick={() => handleMenuClick("explore")}
							className={isExploreOpen ? styles.active : ""}
						>
							Explorar
						</button>
						<button
							type="button"
							onClick={() => handleMenuClick("product")}
							className={isProductOpen ? styles.active : ""}
						>
							Produtos
						</button>
					</div>
					<div className={styles.rightContent}>
						<button type="button" className={styles.mode} onClick={toggleTheme}>
							<img src={srcMode} alt={altMode} width={24} height={24} />
						</button>
						<CustomLink
							href="https://grupoaguiabranca.empregare.com/pt-br/vagas?divisao=478"
							className="btn-secondary"
							target="_blank"
							onClick={() => {
								setIsExploreOpen(false);
								setIsProductOpen(false);
							}}
						>
							Trabalhe conosco
						</CustomLink>
						<CustomLink
							href="/contato"
							className="btn-primary"
							onClick={() => {
								setIsExploreOpen(false);
								setIsProductOpen(false);
							}}
						>
							Atendimento
						</CustomLink>
					</div>
				</div>

				<nav className={styles.nav}>
					<ExploreContainer
						setIsExploreOpen={setIsExploreOpen}
						styles={styles}
						isExploreOpen={isExploreOpen}
					/>
					<ProductContainer
						setIsProductOpen={setIsProductOpen}
						styles={styles}
						isProductOpen={isProductOpen}
						theme={theme}
					/>
				</nav>
			</div>
		</header>
	);
};

export default React.memo(HeaderDesktop);
